import { useState, useContext } from "react";
import { Container, Form, Button, Alert, Card } from "react-bootstrap";
import "../css/register.css";
import axiosInstance from "../interceptor/axiosInterceptor";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../AuthContext";

const Register = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const { setToken } = useContext(AuthContext);

  const handleRegister = async (e) => {
    e.preventDefault();
    if (!email || !password || !confirmPassword) {
      setError("Please fill in all the required fields.");
      return;
    }

    if (password !== confirmPassword) {
      setError("Passwords do not match.");
      return;
    }

    try {
      const response = await axiosInstance.post("/auth/register", {
        email: email,
        password: password,
      });
      console.log("success");
      const token = response.data.token;
      localStorage.setItem("jwtToken", token);
      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      setToken(token);
      navigate("/");
    } catch (error) {
      setError("Registration failed.");
      console.error("Registration failed:", error);
    }
  };

  return (
    <Container className="register-form">
      <h2>Create Personal Account</h2>
      <Card
        className="p-4"
        style={{
          maxWidth: "600px",
          margin: "0 auto",
          marginTop: "40px",
          borderRadius: "20px",
        }}
      >
        {error && <Alert variant="danger">{error}</Alert>}
        <Form onSubmit={handleRegister}>
          <Form.Group>
            <Form.Label className="text-start" style={{ width: "90%" }}>
              Email<span className="required-field">*</span>
            </Form.Label>
            <Form.Control
              type="email"
              placeholder="Enter email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </Form.Group>

          <Form.Group className="mt-3">
            <Form.Label className="text-start" style={{ width: "90%" }}>
              Password<span className="required-field">*</span>
            </Form.Label>
            <Form.Control
              type="password"
              placeholder="Set password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </Form.Group>

          <Form.Group controlId="formConfirmPassword" className="mt-3 mb-3">
            <Form.Label className="text-start" style={{ width: "90%" }}>
              Confirm Password
              <span className="required-field">*</span>
            </Form.Label>
            <Form.Control
              type="password"
              placeholder="Confirm your password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              isInvalid={password !== confirmPassword}
            />
            {password !== confirmPassword && (
              <Form.Control.Feedback type="invalid">
                Passwords do not match.
              </Form.Control.Feedback>
            )}
          </Form.Group>

          <Button variant="primary" type="submit">
            Register
          </Button>
        </Form>
      </Card>
    </Container>
  );
};

export default Register;
