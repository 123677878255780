import React, { useState, useContext } from "react";
import { Form, Button, Container, Alert, Card } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../interceptor/axiosInterceptor";
import "../css/loginform.css";
import { AuthContext } from "../AuthContext";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const { setToken } = useContext(AuthContext);

  const handleLogin = async (e) => {
    e.preventDefault();
    console.log("handleLogin function called");
    if (!email || !password) {
      setError("Please enter username and password.");
      console.log("Please enter username and password.");
      return;
    }

    try {
      const response = await axiosInstance.post("/auth/login", {
        email,
        password,
      });

      const token = response.data.token;
      localStorage.setItem("jwtToken", token);
      setToken(token);
      setEmail("");
      setPassword("");
      navigate("/");
    } catch (error) {
      if (error.response && error.response.status === 403) {
        setError("Invalid username or password.");
      } else {
        setError("Login failed. Please try again later.");
      }
      console.error("Login failed:", error);
    }
  };

  return (
    <Container className="login-form">
      <h1 style={{ textAlign: "center", fontSize: "2rem" }}>
        Welcome Back to CXEX
      </h1>
      <Card
        className="p-4"
        style={{
          maxWidth: "600px",
          margin: "0 auto",
          marginTop: "40px",
          borderRadius: "20px",
        }}
      >
        {error && <Alert variant="danger">{error}</Alert>}
        <Form onSubmit={handleLogin}>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Email address</Form.Label>
            <Form.Control
              type="email"
              placeholder="Enter email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <Form.Text className="text-muted">
              We'll never share your email with anyone else.
            </Form.Text>
          </Form.Group>

          <Form.Group className="mb-3" controlId="formBasicPassword">
            <Form.Label>Password</Form.Label>
            <Form.Control
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </Form.Group>

          <Button
            variant="info"
            type="submit"
            size="sm"
            style={{
              width: "90%",
              marginTop: "20px",
              border: "none",
              fontSize: "1.2rem",
            }}
          >
            Login
          </Button>
        </Form>
      </Card>
    </Container>
  );
};

export default Login;
