import React, { useState } from "react";
import { Card, Form, InputGroup, Button } from "react-bootstrap";
import axiosInstance from "../interceptor/axiosInterceptor";
import { toast } from "react-toastify";

const CreateOrder = ({ onOrderCreated }) => {
  const [buyOrder, setBuyOrder] = useState({ price: "", quantity: "" });
  const [sellOrder, setSellOrder] = useState({ price: "", quantity: "" });

  const buyOrderReady = !isNaN(buyOrder.price) && !isNaN(buyOrder.quantity);
  const sellOrderReady = !isNaN(sellOrder.price) && !isNaN(sellOrder.quantity);

  const createOrder = async (direction, orderForm) => {
    try {
      const response = await axiosInstance.post("api/orders", {
        direction: direction,
        price: orderForm.price,
        quantity: orderForm.quantity,
      });

      if (direction === "BUY") {
        setBuyOrder({ price: "", quantity: "" });
      } else {
        setSellOrder({ price: "", quantity: "" });
      }

      toast.success("Creater Order Successfully");
      onOrderCreated();
    } catch (error) {
      toast.error(error.response.data.message);
      console.log(error);
    }
  };

  return (
    <div className="d-flex justify-content-between">
      <Card className="mt-2 mb-2" style={{ flex: 1, marginRight: "10px" }}>
        <Card.Header>Buy BTC</Card.Header>
        <Card.Body>
          <Form>
            <InputGroup className="mb-3">
              <Form.Control
                type="number"
                step="0.01"
                min="1"
                id="inputPrice"
                placeholder="Price"
                value={buyOrder.price}
                onChange={(e) =>
                  setBuyOrder({ ...buyOrder, price: e.target.value })
                }
              />
              <InputGroup.Text>USD</InputGroup.Text>
            </InputGroup>
            <InputGroup className="mb-3">
              <Form.Control
                type="number"
                step="0.01"
                min="0.01"
                id="inputQuantity"
                placeholder="Quantity"
                value={buyOrder.quantity}
                onChange={(e) =>
                  setBuyOrder({ ...buyOrder, quantity: e.target.value })
                }
              />
              <InputGroup.Text>BTC</InputGroup.Text>
            </InputGroup>
            <Button
              variant="success"
              onClick={() => createOrder("BUY", buyOrder)}
              disabled={!buyOrderReady}
              block
              className="btn-lg"
            >
              Buy
            </Button>
          </Form>
        </Card.Body>
      </Card>

      <Card className="mt-2 mb-2" style={{ flex: 1, marginLeft: "10px" }}>
        <Card.Header>Sell BTC</Card.Header>
        <Card.Body>
          <Form>
            <InputGroup className="mb-3">
              <Form.Control
                type="number"
                step="0.01"
                min="1"
                id="inputPrice"
                placeholder="Price"
                value={sellOrder.price}
                onChange={(e) =>
                  setSellOrder({ ...sellOrder, price: e.target.value })
                }
              />
              <InputGroup.Text>USD</InputGroup.Text>
            </InputGroup>
            <InputGroup className="mb-3">
              <Form.Control
                type="number"
                step="0.01"
                min="0.01"
                id="inputQuantity"
                placeholder="Quantity"
                value={sellOrder.quantity}
                onChange={(e) =>
                  setSellOrder({ ...sellOrder, quantity: e.target.value })
                }
              />
              <InputGroup.Text>BTC</InputGroup.Text>
            </InputGroup>
            <Button
              variant="danger"
              onClick={() => createOrder("SELL", sellOrder)}
              disabled={!sellOrderReady}
              block
              className="btn-lg"
            >
              Sell
            </Button>
          </Form>
        </Card.Body>
      </Card>
    </div>
  );
};

export default CreateOrder;
