import axiosInstance from "../interceptor/axiosInterceptor";
import { PayPalButtons, PayPalScriptProvider } from "@paypal/react-paypal-js";

const PayPal = ({ buyAmount, onTransactionSuccess, setModal }) => {
  async function createOrder(amountUSD) {
    return await axiosInstance
      .post("/api/paypal-order", null, {
        params: { amount: amountUSD },
      })
      .then((response) => response.data)
      .then((order) => order.id);
  }

  async function onApprove(data) {
    return await axiosInstance
      .post(`/api/paypal-order/${data.orderID}/capture`)
      .then((response) => response.data)
      .then((orderData) => {
        const name = orderData.payer.name.given_name;
        alert(`Transaction completed by ${name}`);

        const increaseVirtualAmountResponse = axiosInstance.post(
          `/api/assets/increase-USD/${buyAmount}`
        );
        onTransactionSuccess();
        setModal(false);

        if (increaseVirtualAmountResponse.status === 200) {
          console.log("success add usd");
        } else {
          console.log("fail to add usd");
        }
      });
  }

  return (
    <PayPalScriptProvider>
      <PayPalButtons
        createOrder={() => createOrder(buyAmount)}
        onApprove={onApprove}
      ></PayPalButtons>
    </PayPalScriptProvider>
  );
};

export default PayPal;
