import { Card, Table } from "react-bootstrap";
import { useEffect, useState } from "react";
import "../css/ticks.css";
import axiosInstance from "../interceptor/axiosInterceptor";

const MAX_TICKS = 14;

const Ticks = ({ newTicks }) => {
  const [ticks, setTicks] = useState([]);

  const iniTicks = async () => {
    try {
      const response = await axiosInstance.get("/api/ticks");
      setTicks(fillTicks(response.data));
    } catch (error) {
      console.log(error);
      console.log("fail to generate ticks");
    }
  };

  function fillTicks(tickList) {
    let list = [...tickList];
    list.reverse();
    if (list.length > MAX_TICKS) {
      list = list.slice(0, MAX_TICKS);
    } else if (list.length < MAX_TICKS) {
      let missing = MAX_TICKS - list.length;
      for (let i = 0; i < missing; i++) {
        list.push([NaN, NaN, NaN, NaN]);
      }
    }

    return list.reverse();
  }

  const formatTime = (timestamp) => {
    return new Date(timestamp).toLocaleTimeString();
  };

  const formatNumber = (value) => {
    return value.toFixed(2);
  };

  useEffect(() => {
    iniTicks();
  }, []);

  useEffect(() => {
    let list = [...ticks];
    list.push(...newTicks);
    setTicks(fillTicks(list));
  }, [newTicks]);

  return (
    <Card className="mt-2 mb-2">
      <Card.Header>Recent Ticks</Card.Header>
      <Card.Body>
        <Table
          className="ticks-table"
          style={{ fontVariantNumeric: "tabular-nums" }}
        >
          <thead>
            <tr>
              <th className="text-end">Time</th>
              <th className="text-end">Amount</th>
              <th className="text-end">Price</th>
            </tr>
          </thead>
          <tbody>
            {ticks.map((tick, index) => (
              <tr key={index}>
                <td
                  className={`text-end ${
                    tick[1] === 0
                      ? "text-danger"
                      : tick[1] === 1
                      ? "text-success"
                      : ""
                  }`}
                >
                  {!isNaN(tick[0]) ? formatTime(tick[0]) : "NaN"}
                </td>
                <td
                  className={`text-end ${
                    tick[1] === 0
                      ? "text-danger"
                      : tick[1] === 1
                      ? "text-success"
                      : ""
                  }`}
                >
                  {formatNumber(tick[2])}
                </td>
                <td
                  className={`text-end ${
                    tick[1] === 0
                      ? "text-danger"
                      : tick[1] === 1
                      ? "text-success"
                      : ""
                  }`}
                >
                  {formatNumber(tick[3])}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  );
};

export default Ticks;
