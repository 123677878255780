const MAX_ORDERBOOK_ITEMS = 10;

export function fillOrderBook(direction, itemList) {
  let list = [...itemList];
  const orderDict = {};

  list.forEach((order) => {
    if (!isNaN(order.price)) {
      if (order.price in orderDict) {
        orderDict[order.price].quantity += order.quantity;
      } else {
        // Otherwise, add a new entry in the dictionary
        orderDict[order.price] = {
          price: order.price,
          quantity: order.quantity,
        };
      }
    }
  });

  list = Object.values(orderDict);

  list.sort((a, b) => {
    if (direction === "SELL") {
      return a.price - b.price;
    } else {
      return b.price - a.price;
    }
  });

  if (list.length > MAX_ORDERBOOK_ITEMS) {
    list = list.slice(0, MAX_ORDERBOOK_ITEMS);
  } else if (list.length < MAX_ORDERBOOK_ITEMS) {
    let missing = MAX_ORDERBOOK_ITEMS - list.length;
    for (let i = 0; i < missing; i++) {
      list.push({
        price: NaN,
        quantity: NaN,
      });
    }
  }
  if (direction === "SELL") {
    list.reverse();
  }
  return list;
}
