import { createChart, CrosshairMode, LineStyle } from "lightweight-charts";
import React, { useEffect, useRef, useState } from "react";
import { Card, ButtonGroup, Button } from "react-bootstrap";
import axiosInstance from "../interceptor/axiosInterceptor";

export const ChartComponent = (props) => {
  const {
    data,
    updateData,
    colors: {
      textColor = "rgb(33, 37, 41)",
      upColor = "rgb(25, 135, 84)",
      downColor = "rgb(220, 53, 69)",
    } = {},
  } = props;

  const chartContainerRef = useRef();
  const [chartCandlestickSeries, setChartCandlestickSeries] = useState(null);

  const TZ_OFFSET = new Date().getTimezoneOffset() * 60_000;
  function toSingleChartData(tohlcv) {
    return {
      time: new Date(tohlcv[0] - TZ_OFFSET) / 1000,
      open: tohlcv[1],
      high: tohlcv[2],
      low: tohlcv[3],
      close: tohlcv[4],
    };
  }

  useEffect(() => {
    const handleResize = () => {
      chart.applyOptions({ width: chartContainerRef.current.clientWidth });
    };

    const chart = createChart(chartContainerRef.current, {
      layout: {
        textColor,
      },
      width: chartContainerRef.current.clientWidth,
      timeScale: {
        timeVisible: true,
        secondsVisible: false,
        rightOffset: 10, // Adjust this to give some space on the right side
        barSpacing: 8, // Adjust this to control the space between bars
        minBarSpacing: 5,
      },
      height: 480,
    });

    chart.applyOptions({
      crosshair: {
        mode: CrosshairMode.Normal,

        vertLine: {
          width: 6,
          color: "#C3BCDB44",
          style: LineStyle.Solid,
          labelBackgroundColor: "lightblue",
        },

        horzLine: {
          color: "lightblue",
          labelBackgroundColor: "lightblue",
        },
      },
    });

    const series = chart.addCandlestickSeries({
      upColor: upColor,
      downColor: downColor,
    });

    series.setData(data.map(toSingleChartData));

    if (data.length > 0) {
      const lastIndex = data.length - 1;
      const firstIndex = Math.max(0, lastIndex - 40);
      chart.timeScale().setVisibleRange({
        from: data[firstIndex][0] / 1000,
        to: data[lastIndex][0] / 1000,
      });
    }

    series.priceScale().applyOptions({
      scaleMargins: {
        top: 0.22,
        bottom: 0.22,
      },
    });
    chart.timeScale().fitContent();

    setChartCandlestickSeries(series);

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);

      chart.remove();
    };
  }, [data]);

  useEffect(() => {
    if (chartCandlestickSeries) {
      chartCandlestickSeries.update(toSingleChartData(updateData));
    }
  }, [updateData]);

  return <div ref={chartContainerRef} />;
};

const Chart = (props) => {
  const [chartData, setChartData] = useState([]);
  const [selectedTimeFrame, setSelectedTimeFrame] = useState("min");
  const [dataByTimeFrame, setDataByTimeFrame] = useState({
    sec: [],
    min: [],
    hour: [],
    day: [],
  });
  const [updateData, setUpdateData] = useState();

  const handleTimeFrameChange = (timeFrame) => {
    setSelectedTimeFrame(timeFrame);
  };

  const handleWebSocketMessage = (messages) => {
    setDataByTimeFrame((prevData) => {
      const newData = { ...prevData };

      messages.forEach((message) => {
        const { type, resolution, data } = message;

        if (type === "bar") {
          const lowerResolution = resolution.toLowerCase();
          if (lowerResolution === selectedTimeFrame) {
            setUpdateData(data);
          }

          const existingIndex = newData[lowerResolution].findIndex(
            (entry) => entry[0] === data[0]
          );

          if (existingIndex !== -1) {
            newData[lowerResolution][existingIndex] = data;
          } else {
            newData[lowerResolution].push(data);
          }
        }
      });

      return newData;
    });
  };

  useEffect(() => {
    const iniChartData = async () => {
      try {
        const sec = await axiosInstance.get("/api/bars/sec");
        const min = await axiosInstance.get("/api/bars/min");
        const hour = await axiosInstance.get("/api/bars/hour");
        const day = await axiosInstance.get("/api/bars/day");

        setDataByTimeFrame({
          sec: sec.data,
          min: min.data,
          hour: hour.data,
          day: day.data,
        });

        setChartData(min.data);
      } catch (error) {
        console.error(error);
      }
    };

    iniChartData();
  }, []);

  useEffect(() => {
    setChartData(dataByTimeFrame[selectedTimeFrame]);
  }, [selectedTimeFrame]);

  useEffect(() => {}, [updateData]);

  useEffect(() => {
    handleWebSocketMessage(props.chartMessage);
  }, [props.chartMessage]);

  return (
    <Card>
      <Card.Header>Chart</Card.Header>
      <ButtonGroup>
        <Button
          size="sm"
          variant="secondary"
          active={selectedTimeFrame === "sec"}
          onClick={() => handleTimeFrameChange("sec")}
        >
          1 sec
        </Button>
        <Button
          size="sm"
          variant="secondary"
          active={selectedTimeFrame === "min"}
          onClick={() => handleTimeFrameChange("min")}
        >
          1 min
        </Button>
        <Button
          size="sm"
          variant="secondary"
          active={selectedTimeFrame === "hour"}
          onClick={() => handleTimeFrameChange("hour")}
        >
          1 hour
        </Button>
        <Button
          size="sm"
          variant="secondary"
          active={selectedTimeFrame === "day"}
          onClick={() => handleTimeFrameChange("day")}
        >
          1 day
        </Button>
      </ButtonGroup>
      <ChartComponent
        {...props}
        data={chartData}
        updateData={updateData}
      ></ChartComponent>
    </Card>
  );
};

export default Chart;
