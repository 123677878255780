import axiosInstance from "../interceptor/axiosInterceptor";
import React, { useState, useEffect } from "react";
import { Table, Card } from "react-bootstrap";
import "../css/OrderBook.css";
import { fillOrderBook } from "../util/fillOrderBook";

const OrderBook = ({ newOrderBook }) => {
  const [orderBook, setOrderBook] = useState({
    buy: [],
    sell: [],
    price: 0,
  });

  const formatNumber = (value) => {
    return value.toFixed(2);
  };

  const depthWidth = (quantity) => {
    return `${quantity * 10}px`; // Adjust the width as needed
  };

  const iniOrderBook = async () => {
    try {
      const response = await axiosInstance.get("/api/orderBook");

      const limitedBuyOrders = fillOrderBook("BUY", response.data.buy);
      const limitedSellOrders = fillOrderBook("SELL", response.data.sell);

      setOrderBook({
        buy: limitedBuyOrders,
        sell: limitedSellOrders,
        price: response.data.price,
      });
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    iniOrderBook();
  }, []);

  useEffect(() => {
    setOrderBook(newOrderBook);
  }, [newOrderBook]);

  return (
    <Card className="mt-2">
      <Card.Header>Order Book</Card.Header>
      <Card.Body>
        <Table
          className="order-book-table"
          style={{ fontVariantNumeric: "tabular-nums" }}
        >
          <thead>
            <tr>
              <th className="text-end">Price</th>
              <th className="text-end">Quantity</th>
            </tr>
          </thead>
          <tbody>
            {orderBook.sell.map((item, index) => (
              <tr key={index} className="text-danger">
                <td className="text-end">
                  <a href="#0" className="text-decoration-none text-danger">
                    {formatNumber(item.price)}
                  </a>
                </td>
                <td className="text-end" style={{ position: "relative" }}>
                  <div
                    className="ex-depth ex-depth-sell"
                    style={{ width: depthWidth(item.quantity) }}
                  ></div>
                  <span>{formatNumber(item.quantity)}</span>
                </td>
              </tr>
            ))}
            <tr>
              <td className="text-end">
                <span>{formatNumber(orderBook.price)}</span>
              </td>
              <td></td>
            </tr>
            {orderBook.buy.map((item, index) => (
              <tr key={index} className="text-success">
                <td className="text-end">
                  <a href="#0" className="text-decoration-none text-success">
                    {formatNumber(item.price)}
                  </a>
                </td>
                <td className="text-end" style={{ position: "relative" }}>
                  <div
                    className="ex-depth ex-depth-buy"
                    style={{ width: depthWidth(item.quantity) }}
                  ></div>
                  <span>{formatNumber(item.quantity)}</span>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  );
};

export default OrderBook;
