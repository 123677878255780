import React, { useEffect, useState, useContext } from "react";
import { Table, Card, Modal, Form, Button } from "react-bootstrap";
import axiosInstance from "../interceptor/axiosInterceptor";
import PayPal from "./Paypal";
import { checkIfTokenExists } from "../util/checkIfTokenExists";
import { AuthContext } from "../AuthContext";

const PairBalance = ({ refreshBalance }) => {
  const [showBuyModal, setShowBuyModal] = useState(false);
  const [selectedAmount, setSelectedAmount] = useState(100);
  const [payPalKey, setPayPalKey] = useState(0); // Add a key state
  const { token } = useContext(AuthContext);

  const handleAmountChange = (e) => {
    setSelectedAmount(parseFloat(e.target.value));
  };

  const handleModalClose = () => {
    setShowBuyModal(false);
    setSelectedAmount(100);
    setPayPalKey(0);
  };

  useEffect(() => {
    setPayPalKey(selectedAmount);
  }, [selectedAmount]);

  const testAssets = {
    USD: {
      available: NaN,
      frozen: NaN,
    },
    BTC: {
      available: NaN,
      frozen: NaN,
    },
  };

  const formatNumber = (value) => {
    return value.toFixed(2);
  };

  const [assets, setAssets] = useState(testAssets);

  const refreshAssets = async () => {
    if (!checkIfTokenExists()) {
      return;
    }

    try {
      const response = await axiosInstance.get("api/assets");
      console.log(response);
      setAssets({
        USD: {
          available: response.data.USD.available,
          frozen: response.data.USD.frozen,
        },
        BTC: {
          available: response.data.BTC.available,
          frozen: response.data.BTC.frozen,
        },
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    refreshAssets();
  }, []);

  useEffect(() => {
    if (token) {
      refreshAssets();
    } else {
      setAssets({
        USD: { available: NaN, frozen: NaN },
        BTC: { available: NaN, frozen: NaN },
      });
    }
  }, [token, refreshBalance]);

  return (
    <>
      <Card className="mt-2">
        <Card.Header>Balance</Card.Header>
        <Card.Body>
          <Table
            striped
            bordered
            hover
            size="sm"
            style={{ fontVariantNumeric: "tabular-nums", marginBottom: "0px" }}
          >
            <thead>
              <tr>
                <th>Asset</th>
                <th className="text-end">Available</th>
                <th className="text-end">Frozen</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>USD</td>
                <td className="text-end">
                  {formatNumber(assets.USD.available)}
                </td>
                <td className="text-end">{formatNumber(assets.USD.frozen)}</td>
              </tr>
              <tr>
                <td>BTC</td>
                <td className="text-end">
                  {formatNumber(assets.BTC.available)}
                </td>
                <td className="text-end">{formatNumber(assets.BTC.frozen)}</td>
              </tr>
            </tbody>
          </Table>

          <div className="mt-2">
            {token && (
              <Button variant="success" onClick={() => setShowBuyModal(true)}>
                Buy USD
              </Button>
            )}
          </div>
        </Card.Body>
      </Card>

      <Modal
        show={showBuyModal}
        onHide={handleModalClose}
        style={{
          top: "170px",
          right: "20px",
          left: "20px",
          transform: "none",
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Buy USD</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label>Choose Amount</Form.Label>
              <Form.Control as="select" onChange={handleAmountChange}>
                <option value={100}>100 USD</option>
                <option value={200}>200 USD</option>
                <option value={500}>500 USD</option>
                <option value={1000}>1000 USD</option>
                <option value={5000}>5000 USD</option>
                <option value={10000}>10000 USD</option>
                {/* <option value={0}>Custom Amount</option> */}
              </Form.Control>
            </Form.Group>
            <PayPal
              key={payPalKey}
              buyAmount={selectedAmount}
              onTransactionSuccess={refreshAssets}
              setModal={setShowBuyModal}
            />
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default PairBalance;
