import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import { useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";
import { AuthContext } from "../AuthContext";
import { useContext } from "react";
import axios from "axios";

const Navigater = () => {
  const { token, setToken } = useContext(AuthContext);
  const navigate = useNavigate();

  const handleSignOut = () => {
    delete axios.defaults.headers.common["Authorization"];
    localStorage.removeItem("jwtToken");
    setToken(null);
    navigate("/");
  };

  return (
    <>
      <Navbar fixed="top" expand="lg" className="bg-body-tertiary">
        <Container>
          <Navbar.Brand>CXEX</Navbar.Brand>
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link href="/">Market</Nav.Link>
            </Nav>
            {token ? (
              <div style={{ display: "flex", gap: "10px" }}>
                <Button onClick={handleSignOut}>Sign out</Button>
              </div>
            ) : (
              <Nav>
                <Nav.Link href="/login">Log In </Nav.Link>
                <Nav.Link eventKey={2} href="/register">
                  Register
                </Nav.Link>
              </Nav>
            )}
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
};

export default Navigater;
