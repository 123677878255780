import React, { useEffect, useState, useContext } from "react";
import { Card, Table, Button, Modal } from "react-bootstrap";
import axiosInstance from "../interceptor/axiosInterceptor";
import MyPagination from "./MyPagination";
import { checkIfTokenExists } from "../util/checkIfTokenExists";
import { AuthContext } from "../AuthContext";

const Order = ({ refreshOrders }) => {
  const [showActiveOrders, setShowActiveOrders] = useState(true);
  const [activeOrders, setActiveOrders] = useState([]);
  const [historyOrders, setHistoryOrders] = useState([]);

  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);

  const [showMatchModal, setShowMatchModal] = useState(false);
  const [matchDetails, setMatchDetails] = useState([]);
  const { token } = useContext(AuthContext);

  const handleShowMatchModal = async (orderId) => {
    try {
      const response = await axiosInstance.get(
        `api/history/orders/${orderId}/matches`
      );
      setMatchDetails(response.data);
      setShowMatchModal(true);
    } catch (error) {
      console.error("Failed to fetch order match details");
    }
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const formatNumber = (value) => {
    return value.toFixed(2);
  };

  const formatTime = (timestamp) => {
    const options = {
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    };
    return new Date(timestamp).toLocaleString(undefined, options);
  };

  const cancelOrder = async (orderId) => {
    try {
      const response = await axiosInstance.post(`api/orders/${orderId}/cancel`);
      console.log("success cancel order");
    } catch (error) {
      console.log(error);
      console.error("fail to cancel order");
    }
  };

  const refreshActiveOrders = async () => {
    if (!checkIfTokenExists()) {
      return;
    }
    try {
      const response = await axiosInstance.get("/api/orders");
      console.log("success refresh active orders");
      setActiveOrders(response.data);
    } catch (error) {
      console.error("fail to refresh active orders");
    }
  };

  const refreshHistoryOrders = async () => {
    if (!checkIfTokenExists()) {
      return;
    }
    try {
      const response = await axiosInstance.get("/api/history/orders", {
        params: {
          page: currentPage - 1,
          pageSize: itemsPerPage,
        },
      });
      console.log("success refresh history orders");
      setTotalPages(response.data.totalPages);
      console.log("This is totalPage:" + totalPages);
      console.log(response);
      setHistoryOrders(response.data.content);
    } catch (error) {
      console.error("fail to refresh history orders");
    }
  };

  useEffect(() => {
    refreshActiveOrders();
    refreshHistoryOrders();
  }, [refreshOrders, currentPage]);

  useEffect(() => {
    if (!token) {
      setActiveOrders([]);
      setHistoryOrders([]);
    }
  }, [token]);

  return (
    <Card className="mt-2 mb-2">
      <Card.Header>
        <ul className="nav nav-tabs card-header-tabs">
          <li className="nav-item">
            <a
              className={`nav-link ${showActiveOrders ? "active" : ""}`}
              onClick={() => setShowActiveOrders(true)}
            >
              Active Orders
            </a>
          </li>
          <li className="nav-item">
            <a
              className={`nav-link ${!showActiveOrders ? "active" : ""}`}
              onClick={() => setShowActiveOrders(false)}
            >
              History Orders
            </a>
          </li>
        </ul>
      </Card.Header>
      <Card.Body>
        {showActiveOrders && (
          <Table striped bordered hover size="sm" style={{ lineHeight: "2.2" }}>
            <thead>
              <tr>
                <th>Direction</th>
                <th style={{ width: "20%" }} className="text-end">
                  Price
                </th>
                <th style={{ width: "20%" }} className="text-end">
                  Quantity
                </th>
                <th style={{ width: "20%" }} className="text-end">
                  Unfilled
                </th>
                <th style={{ width: "20%" }} className="text-end">
                  <button
                    style={{ background: "none", border: "none" }}
                    onClick={refreshActiveOrders}
                  >
                    <i className="bi bi-arrow-clockwise"></i>
                  </button>
                </th>
              </tr>
            </thead>
            <tbody>
              {activeOrders.map((order, index) => (
                <tr key={index}>
                  <td>{order.direction}</td>
                  <td className="text-end">{formatNumber(order.price)}</td>
                  <td className="text-end">{formatNumber(order.quantity)}</td>
                  <td className="text-end">
                    {formatNumber(order.unfilledQuantity)}
                  </td>
                  <td className="text-end">
                    <Button
                      variant="warning"
                      size="sm"
                      onClick={() => cancelOrder(order.id)}
                    >
                      Cancel
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}
        {!showActiveOrders && (
          <div>
            <Table
              striped
              bordered
              hover
              size="sm"
              style={{ lineHeight: "2.2" }}
            >
              <thead>
                <tr>
                  <th>Id</th>
                  <th>Direction</th>
                  <th style={{ width: "13%" }}>Status</th>
                  <th style={{ width: "13%" }} className="text-end">
                    Price
                  </th>
                  <th style={{ width: "13%" }} className="text-end">
                    Quantity
                  </th>
                  <th style={{ width: "13%" }} className="text-end">
                    Unfilled
                  </th>
                  <th style={{ width: "20%" }} className="text-end">
                    Date
                  </th>
                  <th>
                    <button
                      style={{ background: "none", border: "none" }}
                      onClick={refreshHistoryOrders}
                    >
                      <i className="bi bi-arrow-clockwise"></i>
                    </button>
                  </th>
                </tr>
              </thead>
              <tbody>
                {historyOrders.map((order, index) => (
                  <tr key={index}>
                    <td>{order.id}</td>
                    <td>{order.direction}</td>
                    <td>{order.status}</td>
                    <td className="text-end">{formatNumber(order.price)}</td>
                    <td className="text-end">{formatNumber(order.quantity)}</td>
                    <td className="text-end">
                      {formatNumber(order.unfilledQuantity)}
                    </td>
                    <td className="text-end">{formatTime(order.updatedAt)}</td>
                    <td>
                      <button
                        style={{ background: "none", border: "none" }}
                        onClick={() => handleShowMatchModal(order.id)}
                      >
                        <i className="bi bi-ticket-detailed"></i>
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <MyPagination
              currentPage={currentPage}
              totalPages={totalPages}
              handlePageChange={handlePageChange}
            />
          </div>
        )}
      </Card.Body>

      <Modal
        show={showMatchModal}
        onHide={() => setShowMatchModal(false)}
        style={{
          top: "450px",
          right: "20px",
          left: "20px",
          transform: "none",
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Match Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <table className="table table-bordered">
            <thead>
              <tr>
                <th>Price</th>
                <th>Quantity</th>
                <th>Type</th>
              </tr>
            </thead>
            <tbody>
              {matchDetails.map((detail, index) => (
                <tr key={index}>
                  <td>{detail.price.toFixed(2)}</td>
                  <td>{detail.quantity.toFixed(2)}</td>
                  <td>{detail.type}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </Modal.Body>
      </Modal>
    </Card>
  );
};

export default Order;
