import { Col, Container, Row } from "react-bootstrap";
import Chart from "../component/Chart";
import CreateOrder from "../component/CreateOrder";
import OrderBook from "../component/OrderBook";
import Ticks from "../component/Ticks";
import PairBalance from "../component/PairBalance";
import Order from "../component/Order";
import { useState, useEffect } from "react";
import { jwtDecode } from "jwt-decode";
import { toast } from "react-toastify";
import { fillOrderBook } from "../util/fillOrderBook";

const Home = () => {
  const [ticks, setTicks] = useState([]);
  const [orderBook, setOrderBook] = useState({
    buy: [],
    sell: [],
    price: 0,
  });
  const [bufferedMessages, setBufferedMessages] = useState([]);

  const [chartMessages, setChartMessages] = useState([]);

  const [wsNotification, setWsNotification] = useState(null);
  const [refresh, setRefresh] = useState(false);

  const handleOrderCreated = () => {
    setRefresh((current) => !current);
  };

  useEffect(() => {
    if (bufferedMessages.length >= 4) {
      bufferedMessages.forEach((message) => {
        try {
          const msg = JSON.parse(message);

          if (msg.type === "bar") {
            setChartMessages((prevChartMessages) => [
              ...prevChartMessages,
              msg,
            ]);
          }
        } catch (error) {
          console.error("Error parsing WebSocket message:", error);
        }
      });

      setBufferedMessages([]);
    }
  }, [bufferedMessages]);

  const handleWebSocketMessage = async (message) => {
    try {
      const msg = JSON.parse(message);
      if (msg.type === "orderbook") {
        setOrderBook({
          buy: fillOrderBook("BUY", msg.data.buy),
          sell: fillOrderBook("SELL", msg.data.sell),
          price: msg.data.price,
        });
      } else if (msg.type === "tick") {
        setTicks(msg.data);
      } else if (msg.type === "bar") {
        setBufferedMessages((prevMessages) => [...prevMessages, message]);
      } else if (msg.type === "order_matched") {
        let text =
          msg.data.status === "PARTIAL_FILLED"
            ? `Order ${msg.data.id} was partially filled.`
            : `Order ${msg.data.id} was fully filled.`;
        toast.success(text);
      } else if (msg.type === "order_canceled") {
        toast.success(`Order ${msg.data.id} canceled.`);
        setRefresh((current) => !current);
      } else {
        console.log("Skipping unknown message type:", msg.type);
      }
    } catch (error) {
      console.error("Error parsing WebSocket message:", error);
    }
  };

  useEffect(() => {
    let userId = null;
    let wsUrl = "wss://crypto-exchange.world/notification";
    // let wsUrl = "ws://localhost:8006/notification";

    const token = localStorage.getItem("jwtToken");
    if (token) {
      userId = jwtDecode(token).sub;
      wsUrl = `wss://crypto-exchange.world/notification?userId=${userId}`;
      // wsUrl = `ws://localhost:8006/notification?userId=${userId}`;
    }

    const webSocket = new WebSocket(wsUrl);

    webSocket.onopen = () => {
      console.log("WebSocket connected.");
      setWsNotification(webSocket);
    };

    webSocket.onclose = () => {
      console.log("WebSocket disconnected.");
    };

    webSocket.onerror = (error) => {
      console.error("WebSocket error:", error);
    };

    webSocket.onmessage = (event) => {
      handleWebSocketMessage(event.data);
    };

    return () => {
      if (webSocket) {
        webSocket.close();
      }
    };
  }, []);

  return (
    <Container style={{ maxWidth: "1500px" }}>
      <Row style={{ marginTop: "30px" }}>
        <Col>
          <div
            style={{
              backgroundColor: "#f8d7da",
              color: "#721c24",
              padding: "8px",
              borderRadius: "5px",
            }}
          >
            <strong>Test User:</strong> test@email.com | Password: 123456
          </div>
        </Col>
      </Row>
      <Row style={{ marginTop: "10px" }}>
        <Col md={2}>
          <OrderBook newOrderBook={orderBook} />
        </Col>
        <Col md={8}>
          <Chart chartMessage={chartMessages} />
          <CreateOrder onOrderCreated={handleOrderCreated} />
        </Col>
        <Col md={2}>
          <Ticks newTicks={ticks} />
          <PairBalance refreshBalance={refresh} />
        </Col>
      </Row>
      <Order refreshOrders={refresh} />
    </Container>
  );
};

export default Home;
